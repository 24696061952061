<template>
  <div>
    <div class="columns is-justify-content-center">
      <div class="column is-3-desktop pb-0">
        <div class="pl-5 py-5 cursor-pointer" :class="{'has-background-primary-light': item.title === activeTab}"
             @click="activeTab = item.title" v-for="(item, key) in tabs" :key="key">
          <p class="is-flex is-align-items-center">
            <b-icon :icon="item.icon" class="mr-5" :class="{'has-text-primary': item.title === activeTab}"/>
            <span>{{ item.title }}</span>
          </p>
        </div>
      </div>
      <div class="column is-9-desktop">
        <div class="pt-4" v-if="activeTab === 'User'">
          <user/>
        </div>
        <div class="pt-4" v-if="activeTab === $t('farm.pond')">
          <pond/>
        </div>
        <div class="pt-4" v-if="activeTab === $t('wq.wq')">
          <water-quality-setting/>
        </div>
        <div class="pt-4" v-if="activeTab === 'Feed Index'">
          <feed-index-setting/>
        </div>
        <div class="pt-4" v-if="activeTab === 'Cost Input'">
          <cost-setting/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Setting",
  components: {
    User: () => import("@/components/Farm/User/User"),
    Pond: () => import("@/components/Farm/Pond/Pond"),
    FeedIndexSetting: () => import("@/components/Farm/Setting/FeedIndexSetting"),
    CostSetting: () => import("@/components/Farm/Setting/CostSetting"),
    WaterQualitySetting: () => import("@/components/Farm/Setting/WaterQualitySetting"),
  },
  computed: {
    canViewWqs() {
      return this.$store.getters["farm/canViewWqs"]
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    ...mapGetters('farm', [
      'isPro',
    ]),
    tabs() {
      let tabs = [
        {
          title: 'User',
          icon: 'account-multiple-outline',
        },
        {
          title: this.$t('farm.pond'),
          icon: 'waves',
        },
      ]
      if (this.isPro) {
        tabs = tabs.concat([
          {
            title: this.$t('wq.wq'),
            icon: 'water-outline',
          },
          {
            title: 'Feed Index',
            icon: 'chart-line',
          },{
            title: 'Cost Input',
            icon: 'cog-outline',
          }
        ])
      }
      return tabs
    }
  },
  watch: {
    activeTab() {
      this.$loading()
    },
  },
  data() {
    return {
      activeTab: 'User',
    }
  },
}
</script>
